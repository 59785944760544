class Utils {
  /**
   * 0보다 크거나 같은 정수인지 체크한다.
   */
  isZeroOrPosInt(value) {
    value = Number(value)
    return Number.isInteger(value) && value >= 0
  }
}

const utils = new Utils()
export default utils