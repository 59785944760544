import _ from 'lodash'
import { useForm } from 'react-hook-form'

import api from '../../../services/api'
import useModal from '../../../hooks/useModal'
import Utils from '../../../utils'

import * as S from '../Modal.styles'
import { BasicNumberInputForForm } from '../../InputBoxes'
import { CompleteBtn } from '../../Buttons'

import { ReactComponent as CloseIcon } from '../../../assets/images/close_delete_icon.svg'

export default function UpdateWeeklyDelayModal(props) {
  const { control, handleSubmit } = useForm()
  const { closeModal } = useModal()

  async function updateWeeklyDelay(data) {
    const { sessionalPlanIdx, totalQuantityIdx, weeklyQuantityIdx, targetWeeklyQuantityIdx } = props

    try {
      api.patch(`/sessional-plans/${sessionalPlanIdx}/total-quantities/${totalQuantityIdx}/weekly-quantities/${weeklyQuantityIdx}/delays`
        , { target_wq_idx: targetWeeklyQuantityIdx, ...data })
        .then(result => {
          let _sessionalPlan = _.cloneDeep(props.sessionalPlan)
          const modifiedIdx = _.findIndex(_sessionalPlan.total_quantity, tq => tq.idx === totalQuantityIdx)
          _sessionalPlan.total_quantity[modifiedIdx].weekly_quantity = result.data.weekly_quantity
          props.setSessionalPlan(_sessionalPlan)
          closeModal()
        })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <S.Background />
      <S.ModalBox height='250px' width='300px'>
        <CloseIcon
          className='close-icon'
          width='25px'
          onClick={closeModal}
        />
        <S.ModalContents width='80%' height='80%'>
          <S.Title>{props?.targetWeekNum} 주차로 딜레이</S.Title>
          <BasicNumberInputForForm
            control={control}
            defaultValue={props?.weeklyDelay || 0}
            label='딜레이 양'
            name='quantity_to_delay'
            maxWidth='50%'
            rules={{
              required: '딜레이 양을 입력해 주세요',
              max: { value: props?.weeklyDelay, message: `${props?.weeklyDelay}보다 클 수 없습니다.` },
              validate: (v) => Utils.isZeroOrPosInt(v) || '0보다 크거나 같은 정수여야 합니다.'
          }}
            style={{ marginTop: '30px' }}
          />
          <S.BtnContainer>
            <CompleteBtn
              fontSize='1em'
              height='40px'
              width='100%'
              onClick={handleSubmit(updateWeeklyDelay)}
            >확인
            </CompleteBtn>
          </S.BtnContainer>
        </S.ModalContents>
      </S.ModalBox>
    </>
  )
}